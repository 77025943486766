@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.responst-item {
  padding: 2rem 1.3rem 2rem 0;
  border-radius: 0.5rem;
  margin-right: 0.75rem;
  background-color: rgb(39, 39, 42);
  background-color: rgb(39, 39, 42);
}

.response-paragraph {
  width: 100%;
  overflow-wrap: break-word;
}

.question-item {
  padding: 2rem 1.3rem 2rem 0;
  padding-left: 1.5rem;
  border-radius: 0.5rem;
  margin-right: -1.3rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  color: #e110e1;
}

.first-line {
  width: 3rem;
  height: 0.1rem;
  background-color: white;
  margin: 0 0 0.8rem 2rem;
}

.second-line {
  width: 3rem;
  height: 0.1rem;
  background-color: white;
  margin: 0 0 0.8rem 2rem;
}

.third-line {
  width: 3rem;
  height: 0.1rem;
  background-color: white;
  margin: 0 0 0 2rem;
}

.rotate {
  transform: rotateY(70deg);
}

.hidden {
  display: none;
}
